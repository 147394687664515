import React from "react";
import {
    CreateGuesser,
    EditGuesser,
    InputGuesser,
    ListGuesser,
    FieldGuesser,
} from "@api-platform/admin";
import { 
    ReferenceInput, 
    AutocompleteInput, 
    ImageField, 
    ImageInput,
    NumberInput,
    SelectInput,
    DateTimeInput,
    useRecordContext 
} from "react-admin";
import { Button, Grid } from "@mui/material";
import {useController, useWatch} from "react-hook-form";

const UploadedImage = () => {
  const record = useRecordContext();
  const image = useController({name: "mediaObject"});
  const watchImage = useWatch({name: "mediaObject"});

  return !!record.image && watchImage !== null ? (
      <>
          <ImageField source="image" title="title"/>
          <Button onClick={() => image.field.onChange(null)}>Remove image</Button>
      </>
  ) : null;
};

export const EventCreate = (props) => (
  <CreateGuesser {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <InputGuesser source={"name"} label="Nombre" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="avatar" reference="avatars" label="Avatar">
          <AutocompleteInput label="Avatar" optionText={"nick"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="domain" reference="domains" label="Dominio">
          <AutocompleteInput label="Dominio" optionText={"name"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateTimeInput source="startDate" label="Fecha Inicio" defaultValue={new Date().toISOString()} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateTimeInput source="endDate" label="Fecha Fin" defaultValue={new Date().toISOString()} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source={"maxUsers"} label="Max. Usuarios" defaultValue={2} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12}>
        <ImageInput source="mediaObject" accept="image/*" label="Imagen">
          <ImageField source="image" title="title" />
        </ImageInput>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"status"} defaultValue='waiting' style={{ display: 'none'}} />
      </Grid>
    </Grid>
  </CreateGuesser>
);

export const EventEdit = (props) => (
  <EditGuesser {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <InputGuesser source={"name"} label="Nombre" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="avatar" reference="avatars" label="Avatar">
          <AutocompleteInput label="Avatar" optionText={"nick"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput source="domain" reference="domains" label="Dominio">
          <AutocompleteInput label="Dominio" optionText={"name"} sx={{ width: '100%' }}/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <InputGuesser source={"description"} label="Descripción" InputProps={{ multiline: true, rows: 4 }} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateTimeInput source="startDate" label="Fecha Inicio" defaultValue={new Date().toISOString()} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateTimeInput source="endDate" label="Fecha Fin" defaultValue={new Date().toISOString()} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source={"maxUsers"} label="Max. Usuarios" defaultValue={2} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <UploadedImage />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageInput source="mediaObject" accept="image/*" label="Imagen">
          <ImageField source="image" title="title"/>
        </ImageInput>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput source={"status"} label="Estado" choices={[
          { id: 'waiting', name: 'Esperando' },
          { id: 'complete', name: 'Completo' },
          { id: 'terminated', name: 'Finalizado' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source={"reports"} label="Reportes" sx={{ width: '100%' }}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput source={"isBlocked"} label="¿Bloqueado?" choices={[
          { id: false, name: 'NO' },
          { id: true, name: 'SI' }
        ]} sx={{ width: '100%' }}/>
      </Grid>
    </Grid>
  </EditGuesser>
);

export const EventList = (props) => (
  <ListGuesser {...props}>
    <ImageField source="image" title="image" label="Imagen" />
    <FieldGuesser source={"avatar"} label="Avatar"/>
    <FieldGuesser source={"domain"} label="Dominio"/>
    <FieldGuesser source={"name"} label="Nombre"/>
    <FieldGuesser source={"description"} label="Descripción"/>
    <FieldGuesser source={"startDate"} label="Fecha Inicio"/>
    <FieldGuesser source={"endDate"} label="Fecha Fin"/>
    <FieldGuesser source={"status"} label="Estado"/>
    <FieldGuesser source={"reports"} label="Reportes"/>
    <FieldGuesser source={"isBlocked"} label="¿Bloqueado?" />
  </ListGuesser>
);
